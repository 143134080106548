@import '@sweetalert2/theme-dark/dark.scss';

.App {
  text-align: center;
}

.App-highlighted {
  color: #12a1bb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title-input {
  margin-top: 35px;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #12a1bb;
}

input {
  border: 1px solid #12a1bb;
  background-color: #212529;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
  transition: all 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.75);

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #12a1bb;
    transition: all 0.2s ease-in-out;
  }

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    border: 1px solid #12a1bb;
    background-color: #3b3838;
  }
}

.btn {
  &.btn-submit {
    border: 1px solid #12a1bb;
    background-color: #212529;
    color: #e9ecef;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.25rem;
    display: inline-block;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    margin-left: auto;
    text-decoration: none;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  }
}


button:hover {
  animation: fadeGradient 4s infinite;
  color: #ffffff;
}

.shh-icon, .search-icon {
  animation: pulse 3s infinite;
}

.shh-icon g, .search-icon g {
  animation: color-fade 3s infinite;
}

@keyframes fadeGradient {
  /* background color gradient fade animation, half opacity */
  0% {
    background-color: rgba(27, 35, 38, 0.25);
  }
  50% {
    background-color: rgba(84, 86, 86, 0.25);
  }
  100% {
    background-color: rgba(27, 35, 38, 0.25);
  }
}

@keyframes color-fade {
  0% {
    fill: #ffffff;
  }
  50% {
    fill: #12a1bb;
  }
  100% {
    fill: #ffffff;
  }
}

@keyframes pulse {
  0% {
    fill: #12a1bb;
    transform: scale(1);
  }
  50% {
    fill: #18dcff;
    transform: scale(1.1);
  }
  100% {
    fill: none;
    transform: scale(1);
  }
}

body .w-md-editor-text-pre > code,
body .w-md-editor-text-input {
  font-size: 23px !important;
  line-height: 24px !important;
}

.form-switch {
  padding-left: 2.5rem;
  display: flex;
  margin-left: 1rem;
  margin-right: 0.75rem;
}

.markdown-holder {
  width: min(100vw, 1000px);
}

.decoration-none {
  text-decoration: none;
}

.swal2-container * {
  color: #e9ecef !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  background-color: #101215 !important;
}