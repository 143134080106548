.secrets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .secret {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #212529;
      border: 1px solid #12a1bb;
      border-radius: 4px;
      padding: 20px;
      margin: 10px;
      width: 300px;
      height: 200px;
      transition: all 0.2s ease-in-out;

      &:hover {
        border: 1px solid #80bdff;
        background-color: #3b3838;
        transition: all 0.2s ease-in-out;
      }

      .secret-title {
        font-size: 1.5rem;
        color: #e9ecef;
        margin-bottom: 10px;
      }

      .secret-value {
        font-size: 1rem;
        color: #e9ecef;
      }
    }
}
