.navigation-inner {
  background-color: rgba(0, 0, 0, 0.6);

  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 30px;
  padding-bottom: 30px;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding-top: 110px;
  overflow-y: auto;
}

.navigation {
  z-index: 9999;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  span {
    font-size: 1.25rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin: 0 1rem;

      a {

        &:hover {
          color: #12a1bb;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navigation {
    ul {
      li {
        margin: 0 0.5rem;
      }
    }

    .navigation-inner {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .content {
    padding-top: 134px;
  }
}
